.bkg__clipped {
    -webkit-clip-path: polygon(30% 0 ,100% 0,100% 100%,0 100%);
    clip-path: polygon(30% 0 ,100% 0,100% 100%,0 100%);
    color: #fff;
    font-size: 3rem;
    font-weight: 700;
    height: 100%;
    letter-spacing: .1em;
    text-transform: uppercase;
    text-align: center;
}