.nav__links>div {
    width: 7px;
    height: 7px;
    background: transparent;
    border-radius: 50%;
    margin-bottom: 7px;
    margin: auto;
   transition:var(--transition);
}

.nav__links:hover {
    color: var(--color-accentCoral);
}

.nav__links:hover div{

    background: var(--color-accentCoral);

}