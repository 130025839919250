.card__clipped {
    
    -webkit-clip-path: polygon(30% 0 ,100% 0,100% 100%,0 100%);
    clip-path: polygon( 0 20% ,100% 0,100% 100%,0 100%);
    color: #0b1847;
    font-size: 3rem;
    font-weight: 700;
    height: 100%;
    letter-spacing: .01em;
    text-transform: uppercase;
    text-align: center;
    padding: 4rem 1rem 2rem 1rem;
}
.card__clipped a{
    color: #0b1847;
}