.accent__word{
    border: 1px solid #4e6cf4;
    padding: 3px;
    text-align: center;
    display: inline-block;
    transform: rotate(-5deg);
}

.header__socials a:hover {
    color: #f1896e;
}
